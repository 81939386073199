import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form, Popover } from 'reactstrap';
import { generateTestId, Key, ModKey, shortcutManager, withTooltip } from 'summer';
import { useKeyboardNavigation } from '../../../hooks/useKeyboardNavigation';
import { MenuItem } from '../../../models/dtos/ger/menuItem';
import { Reducers } from '../../../store/ducks';
import { closeHeaderSearch, GlobalState, openHeaderSearch } from '../../../store/ducks/global.duck';
import { podeAcessarItemMenu } from '../../../utilities/menu.util';
import boxMenu from '../../pages/box/boxMenu';
import connectMenu from '../../pages/connect/connectMenu';
import gerMenu from '../../pages/ger/gerMenu';
import mcgMenu from '../../pages/mcg/mcgMenu';
import mcpMenu from '../../pages/mcp/mcpMenu';
import mfpMenu from '../../pages/mfp/mfpMenu';
import mhoMenu from '../../pages/mho/mhoMenu';
import micMenu from '../../pages/mic/micMenu';
import mlfMenu from '../../pages/mlf/mlfMenu';
import mprMenu from '../../pages/mpr/mprMenu';
import siteMenu from '../../pages/site/siteMenu';

const inputId = 'idheader-search-input';

const HeaderSearch: FC = () => {
  const { currentState, globalParameter, headerSearchHover } = useSelector<Reducers, GlobalState>(state => state.globalReducer);
  const dispatch = useDispatch();

  const [currentSearch, setCurrentSearch] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const inputRef = useRef<HTMLInputElement>();

  const keyboardNav = useKeyboardNavigation(
    {
      enableLeftRight: false,
      selector: '.global-search',
    },
    [showResults]
  );

  useEffect(() => {
    const listener = shortcutManager.listen([ModKey.CTRL], Key.SPACE, handleShortcut);
    return () => listener.unlisten();
  }, []);

  useEffect(
    () => {
      setShowResults(headerSearchHover && currentSearch.length > 0);
      keyboardNav.setFirstItemSelected();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentSearch]
  );

  const toggleHeaderSearchHover = () => {
    if (!headerSearchHover) {
      setCurrentSearch([]);
      dispatch(openHeaderSearch());
    }
  };

  const removerAcentos = text => {
    const mapaAcentosHex = {
      a: /[\xE0-\xE6]/g,
      c: /\xE7/g,
      e: /[\xE8-\xEB]/g,
      i: /[\xEC-\xEF]/g,
      n: /\xF1/g,
      o: /[\xF2-\xF6]/g,
      u: /[\xF9-\xFC]/g,
    };

    let clean = text.toLowerCase();

    Object.keys(mapaAcentosHex).forEach(key => {
      clean = clean.replace(mapaAcentosHex[key], key);
    });

    return clean;
  };

  const getMenu = () => {
    const {
      usuario: { administrador, acessoModulos },
    } = globalParameter;

    const menu = [
      {
        menu: gerMenu,
        name: 'Módulo Principal',
        state: 'GER',
      },
    ];

    if (administrador || acessoModulos.mcg) {
      menu.push({
        menu: mcgMenu,
        name: 'Módulo Contábil',
        state: 'MCG',
      });
    }

    if (administrador || acessoModulos.mcp) {
      menu.push({
        menu: mcpMenu,
        name: 'Módulo Controle Patrimonial',
        state: 'MCP',
      });
    }

    if (administrador || acessoModulos.mfp) {
      menu.push({
        menu: mfpMenu,
        name: 'Módulo Folha de Pagamento',
        state: 'MFP',
      });
    }

    if (administrador || acessoModulos.mho) {
      menu.push({
        menu: mhoMenu,
        name: 'Módulo de Honorários',
        state: 'MHO',
      });
    }

    if (administrador || acessoModulos.mlf) {
      menu.push({
        menu: mlfMenu,
        name: 'Módulo Fiscal',
        state: 'MLF',
      });
    }

    if (administrador || acessoModulos.mpr) {
      menu.push({
        menu: mprMenu,
        name: 'Módulo de Protocolos',
        state: 'MPR',
      });
    }

    if (administrador || acessoModulos.box) {
      menu.push({
        menu: boxMenu,
        name: 'Box',
        state: 'BOX',
      });
    }

    if (administrador || acessoModulos.connect) {
      menu.push({
        menu: connectMenu,
        name: 'Connect',
        state: 'CONNECT',
      });
    }

    if (administrador || acessoModulos.site) {
      menu.push({
        menu: siteMenu,
        name: 'Site',
        state: 'SITE',
      });
    }

    if (administrador || acessoModulos.mic) {
      menu.push({
        menu: micMenu,
        name: 'Integra Contador',
        state: 'MIC',
      });
    }

    return menu;
  };

  const onChange = () => {
    const value = removerAcentos(inputRef.current.value);

    const copy = item => Object.assign({}, item);

    const filter = (item: MenuItem, label) => {
      let result = false;
      if (podeAcessarItemMenu(item, globalParameter, currentState)) {
        if (item.content !== undefined && item.content.length > 0) {
          item.content = item.content.map(copy).filter(it => filter(it, `${label} ${it.label}`));
          result = item.content.length > 0;
        } else {
          result = removerAcentos(label).indexOf(value) !== -1;
        }
      }
      return result;
    };

    setCurrentSearch(
      value.length >= 1
        ? getMenu()
            .map(copy)
            .map(it => {
              it.menu = it.menu.map(copy).filter(item => filter(item, it.name));
              return it;
            })
            .filter(it => it.menu.length > 0)
        : []
    );
  };

  const execAction = (action, e) => {
    toggle(e);
    if (action) {
      e.preventDefault();
      dispatch(action());
    }
  };

  const renderItem = (itemMenu, index) => (
    <li key={index}>
      {itemMenu.content && itemMenu.content.length > 0 ? (
        <>
          {itemMenu.label}
          <ul>{itemMenu.content.map(renderItem)}</ul>
        </>
      ) : (
        <Link color="link" to={itemMenu.to || '#/'} onClick={execAction.bind(null, itemMenu.action)}>
          {itemMenu.label}
        </Link>
      )}
    </li>
  );

  const renderMenu = (main, index) => (
    <li className={`header-search-item-${main.state.toLowerCase()}`} key={index}>
      {main.name}
      <ul>{main.menu.map(renderItem)}</ul>
    </li>
  );

  const onBlur = () => {
    if (!showResults) {
      dispatch(closeHeaderSearch());
      inputRef.current.value = '';
    }
  };

  const toggle = e => {
    if (e.target.id !== inputId) {
      dispatch(closeHeaderSearch());
      inputRef.current.value = '';
      setShowResults(false);
    }
  };

  const prevent = e => e.preventDefault();

  const handleShortcut = () => document.activeElement !== inputRef.current && inputRef.current.focus();
  const onKeyUp = e => e.key === 'Escape' && inputRef.current.blur();
  const onKeyDown = e => {
    if (e.keyCode === Key.ARROW_UP || e.keyCode === Key.ARROW_DOWN) {
      e.preventDefault();
    }
  };

  return (
    <div className="header-search-wrapper m-0">
      <div className={cx('search-wrapper', { 'is-active': headerSearchHover })}>
        <label className="icon-wrapper text-black" htmlFor={inputId} {...generateTestId('header-search-label')}>
          <FontAwesomeIcon icon={['fas', 'search']} />
        </label>
        <Form onSubmit={prevent} autoComplete="off">
          <input
            ref={inputRef}
            onFocus={toggleHeaderSearchHover}
            onBlur={onBlur}
            onChange={onChange}
            onKeyUp={onKeyUp}
            onKeyDown={onKeyDown}
            className="form-control"
            id={inputId}
            name={inputId}
            type="search"
            {...generateTestId('header-search-input')}
          />
          {withTooltip(
            {
              message: 'CTRL + ESPAÇO para abrir o campo de busca',
              placement: 'bottom',
            },
            'header-search-input'
          )}
        </Form>
        <Popover
          target={inputId}
          isOpen={showResults}
          container="body"
          className={`popover-custom-wrapper popover-custom-lg popover-global-search`}
          placement="auto"
          trigger="legacy"
          toggle={toggle}
        >
          <div className="global-search">
            <ul>{currentSearch.map(renderMenu)}</ul>
          </div>
        </Popover>
      </div>
    </div>
  );
};

export default HeaderSearch;
